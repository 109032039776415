<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      fluid
      v-model="showform"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> {{ editedItem.label }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <!-- <v-col cols="12" sm="6" md="6">
              <v-select
                :items="components_list"
                v-model="editedItem.component_id"
                item-value="id"
                label="Component"
                :rules="[(v) => !!v || 'Component required']"
                outlined
                dense
                :readonly="true"
                :disabled="editedItem.required == 1"
                @input="change++"
                @change="component_change"
              >
                <template v-slot:selection="{ item }">
                  {{ item.code + "-" + item.label }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    {{ item.code + "-" + item.label }}
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col> -->
            <v-col
              cols="12"
              sm="3"
              md="3"
              v-if="editedItem.compartment && editedItem.compartment == 'h'"
            >
              <v-select
                :items="tubulars_list"
                v-model="editedItem.well_tubular_id"
                item-value="id"
                item-text="item"
                :label="'String'"
                :rules="[(v) => !!v || 'String required']"
                outlined
                dense
                :readonly="!modify"
                :disabled="editedItem.required == 1"
                @input="change++"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="suppliers"
                v-model="editedItem.made"
                item-value="id"
                item-text="supplier"
                label="Made"
                outlined
                dense
                :readonly="!modify"
                @input="change++"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                v-model="editedItem.sn"
                :label="'Serial number'"
                @input="change++"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model.number="editedItem.pressurerating"
                :label="'Pressure rating (' + pressure_unit + ')'"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                hide-spin-buttons
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="2">
              <v-text-field
                autocomplete="off"
                :label="'Cost (' + $store.state.options[0].devise + ')'"
                type="number"
                outlined
                dense
                v-model.number="editedItem.itemcost"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                hide-spin-buttons
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.type_id == 95">
            <v-col cols="12" sm="3" md="3">
              <v-select
                :items="vsizes_list"
                v-model="editedItem.v_sizeid"
                item-value="id"
                item-text="size"
                label="Size"
                outlined
                dense
                :readonly="!modify"
                @input="change++"
                @change="change_size"
                return-object
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="1" md="1">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model="editedItem.alr"
                :label="'ALR (' + pressure_unit + ')'"
                :readonly="true"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                :items="valves_type"
                v-model="editedItem.v_type"
                item-value="id"
                item-text="label"
                label="Type"
                outlined
                dense
                :readonly="!modify"
                @input="change++"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" v-if="editedItem.v_type">
              <v-text-field
                autocomplete="off"
                :label="editedItem.v_type == 2 ? 'O/C time' : 'O/C turns'"
                type="number"
                outlined
                dense
                v-model.number="editedItem.v_turns"
                :readonly="!modify"
                @input="change++"
                @focus="$event.target.select()"
                hide-spin-buttons
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="editedItem.code == 52">
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model="Hydraulic_oil_return"
                :label="'Hydraulic oil return (' + pressure_unit + ')'"
                hide-spin-buttons
                @input="change++"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model="opening_time"
                :label="'Opening time (Mn)'"
                hide-spin-buttons
                @input="change++"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model="opening_pressure"
                :label="'Opening pressure (' + pressure_unit + ')'"
                hide-spin-buttons
                @input="change++"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                autocomplete="off"
                type="number"
                outlined
                dense
                v-model="dhsv_depth"
                :label="'Depth (' + depth_unit + ')'"
                hide-spin-buttons
                @input="change++"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn
            color="blue darken-1"
            :disabled="change == 0"
            @click.stop="save"
          >
            Save
          </v-btn>
          <v-btn color="blue darken-1" @click.stop="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
//import CREATE_COMPONENT from "../graphql/Well/CREATE_COMPONENT.gql";
import UPDATE_COMPONENT from "../graphql/Well/UPDATE_COMPONENT.gql";

export default {
  components: {},
  props: {
    list: Array,
    components_well: Array,
    welltubulars: Array,
    suppliers: Array,
    showform: Boolean,
    modify: Boolean,
    item: Object,
    well_id: Number,
    pressure_unit: String,
    depth_unit: String,
    vsizes_list: Array,
  },
  data() {
    return {
      valves_type: [
        { id: 1, label: "Manual" },
        { id: 2, label: "Hydraulic" },
      ],
      document: {},
      isDocClose: true,
      ff: 900,
      return_list: [],
      tubulars_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {},
      change: 0,
      depth_min: 0,
      depth_max: 0,
      Hydraulic_oil_return: 0,
      opening_time: 0,
      opening_pressure: 0,
      dhsv_depth: 0,
    };
  },
  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        if (this.item.code == "52")
          if (this.item.dhsv) {
            let A = this.item.dhsv.split(";");
            if (A.length > 0) {
              this.Hydraulic_oil_return = A[0];
              this.opening_time = A[1];
              this.opening_pressure = A[2];
              this.dhsv_depth = A[3];
            }
          }
        this.component_change();
      }
    }
  },
  computed: {
    components_list() {
      return this.components_well.filter(
        (i) =>
          !this.list.map((j) => j.component_id).includes(i.id) ||
          i.id == this.item.component_id
      );

      //else return c;
    },
  },
  methods: {
    change_size(item) {
      let k = this.vsizes_list.findIndex((x) => x.id == item.id);
      if (k >= 0) this.editedItem.alr = this.vsizes_list[k].alr;
    },
    component_change() {
      let i = this.components_well.findIndex(
        (elm) => elm.id == this.editedItem.component_id
      );
      if (i >= 0) {
        this.editedItem.compartment = this.components_well[i].compartment;
        this.editedItem.group_id = this.components_well[i].group_id;
        this.editedItem.csg_order = this.components_well[i].csg_order;
        this.editedItem.orientation = this.components_well[i].orientation;
        this.editedItem.code = this.components_well[i].code;
        this.editedItem.required = this.components_well[i].required;
        this.editedItem.label = this.components_well[i].label;
        this.editedItem.type_id = this.components_well[i].type_id;
        let r = this.list.filter(
          (elm) => elm.id == this.editedItem.component_id
        );
        this.tubulars_list =
          this.editedItem.compartment == "t"
            ? this.welltubulars.filter(
                (elm) =>
                  elm.tubular_id == 1 &&
                  (!r.map((elm) => elm.well_tubular_id).includes(elm.id) ||
                    elm.id == this.editedItem.well_tubular_id)
              )
            : this.welltubulars.filter(
                (elm) =>
                  elm.depthfrom == 0 &&
                  elm.tubular_id != null &&
                  (!r.map((elm) => elm.well_tubular_id).includes(elm.id) ||
                    elm.id == this.editedItem.well_tubular_id)
              );
      }
    },

    close() {
      this.$emit("close");
    },
    async save() {
      if (this.$refs.form.validate()) {
        let v_sizeid = null;
        if (this.editedItem.v_sizeid) v_sizeid = this.editedItem.v_sizeid.id;
        let i = this.suppliers.findIndex(
          (elm) => elm.id == this.editedItem.made
        );
        if (i >= 0) {
          this.editedItem.supplier = this.suppliers[i].supplier;
        }
        if (this.editedItem.compartment == "h") {
          let j = this.tubulars_list.findIndex(
            (elm) => elm.id == this.editedItem.well_tubular_id
          );
          if (j >= 0) {
            this.editedItem.label =
              (this.tubulars_list[j].annulus
                ? this.tubulars_list[j].annulus
                : this.tubulars_list[j].item) +
              " " +
              this.editedItem.label;
            this.editedItem.code =
              (this.tubulars_list[j].annulus_letter
                ? this.tubulars_list[j].annulus_letter + "NN"
                : this.tubulars_list[j].code) + this.editedItem.code;
          }
        }
        let dhsv = "";
        if (this.editedItem.code == "52") {
          dhsv =
            String(this.Hydraulic_oil_return) +
            ";" +
            String(this.opening_time) +
            ";" +
            String(this.opening_pressure) +
            ";" +
            String(this.dhsv_depth);
        }
        if (this.editedItem.id > -1) {
          let v = {
            wellscomponent: {
              id: this.editedItem.id,
              well_tubular_id: this.editedItem.well_tubular_id,
              component_id: this.editedItem.component_id,
              alr: this.editedItem.alr,
              v_sizeid: v_sizeid,
              v_type: this.editedItem.v_type,
              v_turns: this.editedItem.v_turns,
              v_time: this.editedItem.v_time,
              sn: this.editedItem.sn,
              made: this.editedItem.made,
              dhsv: dhsv,
              pressurerating: this.editedItem.pressurerating,
              itemcost: this.editedItem.itemcost,
              write_uid: this.$store.state.me.id,
            },
          };
          let r = await this.$maj(UPDATE_COMPONENT, v);
          if (r.ok) {
            this.$emit("change", this.editedItem);
            this.list.splice(this.editedItem.index, 1, this.editedItem);
          } else {
            this.snackbar_text = "Saving Error";
            this.snackbar_color = "error";
            this.snackbar = true;
          }
        }

        this.change = 0;
        this.$emit("close_cmp", this.editedItem);
      }
    },
  },
};
</script>
